import * as Styled from "./thankYouPageStyles";
import React from "react";
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import isExternalUrl, { addTrailingSlash, isExternalUrlhref } from '../../utils';
import ContentfulAssets from '../../hooks/useContentfulAssets';

const ThankYouPage=({sectionData})=>{

    const optionsMainStyle = {
        renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.TextContainer>{children}</Styled.TextContainer>
        ),
        [BLOCKS.HEADING_1]: (node, children) => (
            <Styled.HeaderContainer>{children}</Styled.HeaderContainer>
        ),
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const asset = ContentfulAssets(node?.data?.target?.sys?.id);
            if (asset) {
            return (
                <Styled.Link
                href={
                    isExternalUrlhref('/')
                        ? '/'
                        : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + '/')
                    }
                target={isExternalUrl('/') ? '_blank' : '_self'}
                ><Styled.Logo
                src={asset?.node?.file?.url}
                alt={asset?.node?.title}
                /></Styled.Link>
            );
            }
        },
    },
    renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
    };
    return<Styled.Container>
        {sectionData?.contentDetails &&
            documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
        )}
        <Styled.Link 
        href={
            isExternalUrlhref('/')
                ? '/'
                : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + '/')
            }
        target={isExternalUrl('/') ? '_blank' : '_self'}
        >Back To Home</Styled.Link>
    </Styled.Container>
}

export default ThankYouPage;